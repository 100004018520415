import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHeaders,
} from "@angular/common/http";
import { ApiConfiguration } from "./api-configuration";
import { Observable as __Observable, throwError, from, Observable } from "rxjs";
import {
  map as __map,
  filter as __filter,
  catchError,
  retry,
  map,
} from "rxjs/operators";
import { AuthenticationCheckService } from "../auth/authentication-check.service";

//const REPORT_URL = 'https://api1.staging4.bigcityvoucher.co.in/v1/adminreport';
const REPORT_URL = 'http://api1.staging11.bigcityvoucher.co.in/v1/adminreport';
const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
  observe: "response" as "response",
};

@Injectable({
  providedIn: "root",
})
export class ApiService {
  authToken;
  constructor(
    private httpClient: HttpClient,
    public config: ApiConfiguration,
    private auth: AuthenticationCheckService
  ) {
    this.authToken = auth.getAuthToken();
  }

  private getAPIHeaders() {
    return new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: this.auth.getAuthToken(),
      Accept: 'application/json',
    });
  }

  // Get details from server
  public get(url: any): __Observable<[]> {
    let headers = this.getAPIHeaders();
    return this.httpClient.get<any>(url, { headers: headers });
  }

  public getAPI(url: any): Observable<any[]> {
    let headers = this.getAPIHeaders();
    return this.httpClient
      .get(url, { headers: headers })
      .pipe(catchError(this.handleError))
      .map((response) => {
        return <any[]>response;
      });
  }

  getDailyReports(region, reportDate, stateId, reportId): Observable<any[]> {
    let headers = this.getAPIHeaders();
    let apiUrl =
      REPORT_URL + "/loaddailyreports?program_id=" +
      1 +
      "&region_id=" +
      region +
      "&report_date=" +
      reportDate +
      "&state_id=" +
      stateId +
      "&report_id=" +
      reportId +
      "&calendar_year=2021";
    return this.httpClient
      .get(apiUrl, { headers: headers })
      .pipe(catchError(this.handleError))
      .map((response) => {
        return <any[]>response;
      });
  }


  getWeeklyReports(date, region, report, calendar_year): Observable<any[]> {
    let headers = this.getAPIHeaders();
    let apiUrl =
      REPORT_URL +
      "/weeklyreports?program_id=" +
      1 +
      "&date=" +
      date +
      "&report_id=" +
      report +
      "&region_id=" +
      region;
    return this.httpClient
      .get(apiUrl, { headers: headers })
      .pipe(catchError(this.handleError))
      .map((response) => {
        return <any[]>response;
      });
  }

  getMonthlyReports(month, year, region, report, calendar_year): Observable<any[]> {
    let headers = this.getAPIHeaders();
    let apiUrl =
      REPORT_URL +
      "/downloadmonthlyreports?program_id=" +
      1 +
      "&month=" +
      month +
      "&year=" +
      year +
      "&region_id=" +
      region +
      "&report_id=" +
      report;
    return this.httpClient
      .get(apiUrl, { headers: headers })
      .pipe(catchError(this.handleError))
      .map((response) => {
        return <any[]>response;
      });
  }

  getFireBaseReports(
    month,
    year,
    region,
    report,
    startdate,
    enddate
  ): Observable<any[]> {
    let headers = this.getAPIHeaders();
    let apiUrl =
      REPORT_URL +
      "/firebasereport?report_header_id=" +
      report +
      "&startdate=" +
      startdate +
      "&enddate=" +
      enddate +
      "&year=" +
      year +
      "&month=" +
      month +
      "&region=" +
      region + "&program_id=1";
    return this.httpClient
      .get(apiUrl, { headers: headers })
      .pipe(catchError(this.handleError))
      .map((response) => {
        return <any[]>response;
      });
  }

  public getHeaders(url: any): __Observable<any> {
    return this.httpClient.get<any>(url, { observe: "response" });
  }

  public getPostHeaders(url: any, data: any): __Observable<any> {
    return this.httpClient.post<any>(url, data, { observe: "response" });
  }

  // post details to server
  public post(url, data: any): __Observable<any> {
    let headers = { 'Content-Type': 'application/json' };
    return this.httpClient.post<any>(url, data, httpOptions).pipe(catchError(this.handleError))
  }

  // put details to server
  public put(url, data: any): __Observable<any> {
    let headers = this.getAPIHeaders();
    return this.httpClient
      .put<any>(
        url,
        data,
        { headers: headers }
      )
      .map(response => {
        return response;
      })
      .catch(this.handleError);
  }

  // put details to server
  public delete(url, data: any): __Observable<any> {
    let headers = { "Content-Type": "application/json" };
    return this.httpClient.delete<any>(url, data);
  }

  public postWithFormData(url, data: any): __Observable<any> {
    let headers = { "Content-Type": "application/json" };
    return this.httpClient.post<any>(url, data, {
      headers,
      responseType: "json",
    });
  }

  // Post with Progress report
  public upload(url, files: FileList, data): __Observable<HttpEvent<any>> {
    return this.httpClient.post<any>(url, data, {
      reportProgress: true,
      observe: "events",
      headers: { skip: "true" },
    });
  }

  // Error handling
  private handleError(error: HttpErrorResponse): Observable<any> {
    let errorMessage = "";
    let toasterMessage = "";
    if (error.error instanceof ErrorEvent) {
      toasterMessage = error.error.message;
    } else {
      // server-side error
      toasterMessage = error.error.data.message;
    }
    return throwError(toasterMessage);
  }
}
