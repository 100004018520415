/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {
  //SURESH 192.168.1.167
  //baseUrl2 = "http://localhost/budhubadminapi/web/v1";
  baseUrl2 = "http://api.staging11.bigcityvoucher.co.in/v1";
  //baseUrlMobileAPI = "http://api1.staging11.bigcityvoucher.co.in/v1";
  //baseUrl2 = "https://api3.staging4.bigcityvoucher.co.in/v1";
  //baseUrlMobileAPI = "http://api1.staging4.bigcityvoucher.co.in/v1";
  login = this.baseUrl2 + "/login/login";
  getDashBoardDetails = 
    this.baseUrl2 + "/adminreport/generalreports?program_id=1&";
  //***MASTERDATA***/
  // Region
  configGetRegion = this.baseUrl2 + "/region/get-region?program_id=1";
  configAddRegion = this.baseUrl2 + "/region/create-region";
  configUpdateRegion = this.baseUrl2 + "/region/update-region";
  configDeleteRegion = this.baseUrl2 + "/region/delete-region";
  importExcelRegion = this.baseUrl2 + "/region/import-region";
  //states
  configGetStates = this.baseUrl2 + "/state/get-state";
  configAddState = this.baseUrl2 + "/state/create-state";
  configEditState = this.baseUrl2 + "/state/update-state";
  configDeleteState = this.baseUrl2 + "/state/delete-state";
  importExcelState = this.baseUrl2 + "/state/import-state";
  //Cities
  configGetCities = this.baseUrl2 + "/city/get-city?program_id=1";
  configAddCity = this.baseUrl2 + "/city/create-city";
  configEditCity = this.baseUrl2 + "/city/update-city";
  configDeleteCity = this.baseUrl2 + "/city/delete-city";
  importExcelCity = this.baseUrl2 + "/city/import-city";
  //Brands
  configGetBrands = this.baseUrl2 + "/brands/get-brand?program_id=1";
  configAddBrand = this.baseUrl2 + "/brands/create-brand";
  configEditBrand = this.baseUrl2 + "/brands/update-brand";
  configDeleteBrand = this.baseUrl2 + "/brands/delete-brand";
  //Channels
  configGetChannel = this.baseUrl2 + "/channels/get-channel?program_id=1";
  configAddChannel = this.baseUrl2 + "/channels/create-channel";
  configEditChannel = this.baseUrl2 + "/channels/update-channel";
  configDeleteChannel = this.baseUrl2 + "/channels/delete-channel";
  // Languages
  configGetLanguages = this.baseUrl2 + "/language/get-languages";
  configAddLanguage = this.baseUrl2 + "/language/create-language";
  configEditLanguage = this.baseUrl2 + "/language/update-language";
  configDeleteLanguage = this.baseUrl2 + "/channels/delete-channel";
  // Frequency
  configGetFrequency = this.baseUrl2 + "/frequency/get-frequency";
  configAddFrequency = this.baseUrl2 + "/frequency/create-frequency";
  configEditFrequency = this.baseUrl2 + "/frequency/update-frequency";
  // Units
  configGetUnit = this.baseUrl2 + "/units/get-units";
  configAddUnit = this.baseUrl2 + "/units/create-unit";
  configEditUnit = this.baseUrl2 + "/units/update-unit?id=";
  // Brand Categories
  configGetBrandCategory =
    this.baseUrl2 + "/brand-category/get-brand-categories";
  configAddBrandCategory =
    this.baseUrl2 + "/brand-category/create-brand-category";
  configUpdateBrandCategory =
    this.baseUrl2 + "/brand-category/update-brand-category"; //same for delete

  //ROLES
  configGetRoles = this.baseUrl2 + "/user-role/get-user-roles";
  configAddRoles = this.baseUrl2 + "/user-role/create-user-role";
  configUpdateRoles = this.baseUrl2 + "/user-role/update-user-role"; //same for delete
  //***USER MANAGEMENT***/
  allUsers = this.baseUrl2 + "/users/get-users";
  allProfiles = this.baseUrl2 + "/user-role/get-user-roles";
  createUserPost = this.baseUrl2 + "/users/create-user";
  updateUserPost = this.baseUrl2 + "/users/update-user?id=";
  importExcelUser = this.baseUrl2 + "/users/import-users";

  getUserById = this.baseUrl2 + "/users/get-users?id=";
  //Filter data, regions,channel,roles, geography
  filterDataParams = this.baseUrl2 + "/course/get-course?program_id=1";
  //GET OUTLETS MAPPED TO A TSE
  getMappedOutlets = this.baseUrl2 + "/visit-tracker/get-tse-outlets";
  //visit tracker
  getCampaingsBasedOnFilter = this.baseUrl2 + "/campaign/search-campaigns";
  getTasksBasedOnCampaign =
    this.baseUrl2 + "/visit-tracker/visit-tracker-tasks?campaign_id=";
  getVtTseList = this.baseUrl2 + "/visit-tracker/visit-tracker-tse?task_id=";
  //**Channel**//
  getAllChannel = this.baseUrl2 + "/channels/get-channel?program_id=1";

  //***REWARDS***//
  //reward category
  getRewardCategories =
    this.baseUrl2 + "/rewards/get-reward-category?program_id=1";
  addRewardCategories = this.baseUrl2 + "/rewards/create-reward-category";
  editRewardCategories = this.baseUrl2 + "/rewards/update-reward-category";
  deleteRewardCategories = this.baseUrl2 + "/rewards/delete-reward-category";
  rewardHistory = this.baseUrl2 + "/rewards/get-reward-orders?program_id=1";
  getRewardProducts =
    this.baseUrl2 + "/rewards/get-reward-products?program_id=1";
  getRewardProductbyId =
    this.baseUrl2 + "/rewards/get-reward-products?program_id=1&id=";
  createRewardProducts = this.baseUrl2 + "/rewards/create-reward-products";
  updateRewardProducts = this.baseUrl2 + "/rewards/update-reward-products";
  getRewardSubCategory =
    this.baseUrl2 +
    "/rewards/get-reward-sub-categories?program_id=1&reward_category_id=";
  getRewardsBrands =
    this.baseUrl2 +
    "/rewards/get-reward-category-brands?program_id=1&reward_category_id=";

  //reward sub category
  getRewardSubCategories =
    this.baseUrl2 + "/rewards/get-reward-subcategory?program_id=1";
  addRewardSubCategories = this.baseUrl2 + "/rewards/create-reward-subcategory";
  editRewardSubCategories =
    this.baseUrl2 + "/rewards/update-reward-subcategory";
  deleteRewardSubCategories =
    this.baseUrl2 + "/rewards/delete-reward-subcategory";
  //Reward Type
  getRewardType = this.baseUrl2 + "/reward-type/get-reward-types";
  addRewardType = this.baseUrl2 + "/reward-type/create-reward-type?id=1";
  editRewardType = this.baseUrl2 + "/reward-type/update-reward-type?id=";
  deleteRewardType = this.baseUrl2 + "/reward-type/update-reward-type?id=";
  // Engagement API
  engagementCreateActivity =
    this.baseUrl2 + "/engagement/create-campaign-activity";
  engagementUpdate = this.baseUrl2 + "/engagement/update-task?id=";
  getAllEngagement = this.baseUrl2 + "/engagement/get-engagements";
  getEngagementById = this.baseUrl2 + "/engagement/get-engagements?id=";
  getEngagementTemplateById = this.baseUrl2 + "/engagement/get-picture-template?task_id=";
  createEngagementTask = this.baseUrl2 + "/engagement/create-task";
  getUserAssignment = this.baseUrl2 + "/user-modules/get-all-whitelisted-user";
  getUsersFilter = this.baseUrl2 + "/users/search-users-by-filter";
  getQuizDetails = this.baseUrl2 + "/engagement/get-quiz-activities?";
  assignUserDetails = this.baseUrl2 + "/engagement/assign-user-task";
  createQuizEngage = this.baseUrl2 + "/engagement/create-quiz";
  updateQuizEngage = this.baseUrl2 + "/engagement/update-quiz?id=";
  createpointsConfig =
    this.baseUrl2 + "/engagement/create-engagement-point-structure";
  getEngagementTaskPoints =
    this.baseUrl2 + "/engagement/engagement-point-structure?task_id=";
  getAssignedUsers = this.baseUrl2 + "/engagement/get-user-tasks?task_id=";
  uploadImages = this.baseUrl2 + "/engagement/upload-img";
  setQuizCreationCompleteForcampaign =
    this.baseUrl2 + "/engagement/set-quiz-creation-complete-forcampaign";
  /**
   * Activity
   */
  campaign_activites = this.baseUrl2 + "/engagement/get-campaign-activites";
  createActivity = this.baseUrl2 + "/engagement/create-campaign-activity";
  updateActivity = this.baseUrl2 + "/engagement/update-campaign-activity?id=";
  getActivityBasedOnTask =
    this.baseUrl2 + "/engagement/get-campaign-activites?campaign_id=";
  //orders
  getAllOrders = this.baseUrl2 + "/ecommerce/get-ecommerce-orders";
  getOrderDetails = this.baseUrl2 + "/ecommerce/view-ecommerce-order?id=";
  orderApproveReject = this.baseUrl2 + "/ecommerce/accept-reject-order";
  getAllCategiry = this.baseUrl2 + "/brand-category/get-brand-categories";
  createCatgory = this.baseUrl2 + "/ecommerce/create-ecommerce-categories";
  updateCategory = this.baseUrl2 + "/ecommerce/update-ecommerce-categories";

  //***REWARDS***//
  getAllOffers =
    this.baseUrl2 + "/ecommerce/loaddata-for-offer-details?program_id=1";
  //Schemes
  getSchemes = this.baseUrl2 + "/scheme/get-scheme";
  addSchemes = this.baseUrl2 + "/scheme/create-scheme";
  getSchemeConfig = this.baseUrl2 + "/scheme/get-scheme?id=";
  getSchemeProductCategory = this.baseUrl2 + "/scheme/get-brand-category";
  //Visibility /get-posm-config
  getVisibilityList =
    this.baseUrl2 + "/visibility/get-posm-elements?program_id=1";
  addVisibility = this.baseUrl2 + "/visibility/create-posm-elements";
  editVisibility = this.baseUrl2 + "/visibility/update-posm-elements";
  deleteVisibility = this.baseUrl2 + "/visibility/delete-posm-elements";
  saveVisibilityConfig = this.baseUrl2 + "/visibility/create-posm-config";
  getVisibilityConfig = this.baseUrl2 + "/visibility/get-posm-config";
  updateVisibilityConfig = this.baseUrl2 + "/visibility/update-posm-config";
  getVisibilitySubmittedList =
    this.baseUrl2 + "/visibility/get-posm-audit-transactions?page=";
  visibilityApproveOrReject =
    this.baseUrl2 + "/visibility/posm-audit-approve-reject";
  getPosmOnChannel =
    this.baseUrl2 +
    "/visibility/get-channel-posm-elements?program_id=1&channel_id=";
  getChillerSelection =
    this.baseUrl2 + "/visibility/get-chiller-pureimpure-selection";
  chillerSelectionCreate =
    this.baseUrl2 + "/visibility/chiller-pureimpure-selection-create";
  chillerSelectionUpdate =
    this.baseUrl2 + "/visibility/chiller-pureimpure-selection-update";
  getChillerRegionScheme =
    this.baseUrl2 + "/visibility/get-chiller-regionwise-scheme";
  chillerRegionSchemeCreate =
    this.baseUrl2 + "/visibility/chiller-regionwise-scheme-create";
  chillerRegionSchemeUpdate =
    this.baseUrl2 + "/visibility/chiller-regionwise-scheme-update";
  getPosmAuditTypes = this.baseUrl2 + "/visibility/get-posm-audit-types";
  updatePosmAuditTypes = this.baseUrl2 + "/visibility/update-posm-audit-types";
  createPosmAuditTypes = this.baseUrl2 + "/visibility/create-posm-audit-types";

  //campaign
  getAllCampaign = this.baseUrl2 + "/campaign/get-campaigns";
  addCampaign = this.baseUrl2 + "/campaign/create-campaign";
  editCampaign = this.baseUrl2 + "/campaign/update-campaign";
  //* GEOGRAPHY *//
  getAllGeographies = this.baseUrl2 + "/geographical/get-geographs";
  addGeography = this.baseUrl2 + "/geographical/create-geography";
  editGeography = this.baseUrl2 + "/geographical/update-geography?id=";
  //ecommerce
  getProductsList = this.baseUrl2 + "/brands/get-products";
  getProductById =
    this.baseUrl2 + "/product/editproduct?program_id=1&product_id=";
  getProductDelete = this.baseUrl2 + "/product/deleteproduct";
  addToProductList = this.baseUrl2 + "/product/addnewproduts";
  getProductCategories =
    this.baseUrl2 + "/ecommerce/get-ecommerce-categories?program_id=1";
  updateProduct = this.baseUrl2 + "/brands/update-product";
  createGeography = this.baseUrl2 + "/geographical/create-geography";
  getProductVarient = this.baseUrl2 + "/product/get-product-variants";
  getProductVarientValues =
    this.baseUrl2 + "/product/get-product-variant-values?product_variant_id=";
  createOfferDetails = this.baseUrl2 + "/ecommerce/create-offer-details";
  getOfferDetails =
    this.baseUrl2 + "/ecommerce/get-offer-details?program_id=1&offer_id=";
  getEcommerceSubCategories =
    this.baseUrl2 +
    "/ecommerce/get-ecommerce-sub-categories?program_id=1&category_id=";
  //****carton Insert****//
  getCartonList = this.baseUrl2 + "/tertiary-programs/get-cartons?program_id=1";
  getCartonCodes =
    this.baseUrl2 +
    "/tertiary-programs/get-cartons-codes?program_id=1&carton_id=";
  createCarton = this.baseUrl2 + "/tertiary-programs/create-cartons";
  generateQRImages =
    this.baseUrl2 + "/tertiary-programs/qr-code-generation-api";
  /**
   * Content & learning
   */
  getContenentAndLearning =
    this.baseUrl2 + "/content-learning/get-course?program_id=1&course_id=";
  getTaskTypes = this.baseUrl2 + "/content-learning/course-type";
  courseCreation = this.baseUrl2 + "/content-learning/create-course";
  courseUserAssign = this.baseUrl2 + "/content-learning/map-course-users";
  updateContentAndLearning = this.baseUrl2 + "/content-learning/update-course";
  deleteContentAndLearning = this.baseUrl2 + "/content-learning/delete-course";
  createCourseQuiz = this.baseUrl2 + "/content-learning/create-quiz";
  updateCourseQuiz = this.baseUrl2 + "/content-learning/update-quiz";
  deleteCourseQuiz = this.baseUrl2 + "/content-learning/delete-quiz";
  getAllCourseQuiz =
    this.baseUrl2 + "/content-learning/get-quiz?program_id=1&course_id=";
  getContentAndLearningMappedUser =
    this.baseUrl2 + "/content-learning/mapped-course-user-lists";
  getContentAndLearningUserTransaction =
    this.baseUrl2 + "/content-learning/course-user-transaction";

  /**
   * Visit tracker
   */
  getVisits = this.baseUrl2 + "/visit-tracker/get-visits?program_id=1";
  createVisits = this.baseUrl2 + "/visit-tracker/create-visits";
  getVisitEnrollment =
    this.baseUrl2 + "/visit-tracker/get-enrollments?program_id=1";
  approveRejectEnroll =
    this.baseUrl2 + "/visit-tracker/approve-reject-enrollment";
  saveScheme = this.baseUrl2 + "/scheme/create-scheme-data";
  createEnroll = this.baseUrl2 + "/visit-tracker/create-enrollment";
  getOfferById = this.baseUrl2 + "/ecommerce/edit-offers?program_id=1&";

  /****/
  getTargetAchivementList = this.baseUrl2 + "/loyalty-target/get-targets";
  updateTargetAchivement = this.baseUrl2 + "/loyalty-target/update-target";
  addTargetAchivement = this.baseUrl2 + "/loyalty-target/create-target";
  assignedLoyaltyTarget = this.baseUrl2 + "/loyalty-target/get-loyalty-targets";
  createOffer = this.baseUrl2 + "/ecommerce/create-offers";
  deleteOffer = this.baseUrl2 + "/ecommerce/delete-offers";
  updateOffer = this.baseUrl2 + "/ecommerce/update-offers";
  createWhitelist = this.baseUrl2 + "/user-modules/create-user-module";
  getModule = this.baseUrl2 + "/module/get-modules";
  getUserModules = this.baseUrl2 + "/user-modules/get-user-modules";
  updateUserModules = this.baseUrl2 + "/user-modules/update-user-module";
  createLoyalty = this.baseUrl2 + "/loyalty-target/add-target-point-structure";
  getAssignedUser = this.baseUrl2 + "/loyalty-target/get-assigned-users";
  getLoyaltyTargets = this.baseUrl2 + "/loyalty-target/get-loyalty-targets";
  getPointStructure = this.baseUrl2 + "/points-structure/get-points-structure";
  updateLoyaltyTarget = this.baseUrl2 + "/loyalty-target/update-loyalty-target";
  updateLoyaltyProductTarget = this.baseUrl2 + "/loyalty-target/update-product-wise-target";
  updatePointsTarget =
    this.baseUrl2 + "/points-structure/update-points-structure";
  updatePointsProductTarget =
    this.baseUrl2 + "/points-structure/update-product-wise-points";
  /**Excel section */
  uploadMultiProducts = this.baseUrl2 + "/product/upload-products";
  uploadMultiRewards = this.baseUrl2 + "/rewards/create-reward-products-excel";
  createSalesAchivement = this.baseUrl2 + "/loyalty-target/create-achievements";
  getSalesAchivements =
    this.baseUrl2 + "/loyalty-target/get-loyalty-achievements";
  getLoyaltyNotIn = this.baseUrl2 + "/loyalty-target/get-unique-loyalty-achievements";
  updateSalesAchievement =
    this.baseUrl2 + "/loyalty-target/update-loyalty-achievement";
  approveSalesAchievement =
    this.baseUrl2 + "/loyalty-target/approve-sales-achievements";
  filterByCity = this.baseUrl2 + "/city/filter-city";
  productUploadExcelFields = this.baseUrl2 + "/product/product-upload-fields";
  getReardProductsWithPagination =
    this.baseUrl2 + "/rewards/get-reward-products-filter?program_id=1";
  // USER POINTS

  getUserPoints = this.baseUrl2 + "/users/get-user-points";
  createUserPoints = this.baseUrl2 + "/users/create-user-point";
  uploadUserPoints = this.baseUrl2 + "/users/upload-user-point";
  uploadUserAssignment = this.baseUrl2 + "/engagement/upload-engagement-users";
  uploadUserAssignmentCL = this.baseUrl2 + "/content-learning/upload-content-learning-users";
  getGeoGroups = this.baseUrl2 + "/users/get-geo-group";
  updateGeoGroups = this.baseUrl2 + "/users/update-geo-group";
  createGeoGroups = this.baseUrl2 + "/users/create-geo-group"
  getGeoUser = this.baseUrl2 + "/users/get-geo-group-users";
  updateGeoUser = this.baseUrl2 + "/users/update-geo-group-users";
  BulkLoadGeoUser = this.baseUrl2 + "/users/upload-geo-group-users";
  UpdateUserMobile = this.baseUrl2 + "/users/update-user-mobile";
  getEngagementImagesURL = this.baseUrl2 + "/engagement/get-engagement-images";
  uploadVisiblityUsers = this.baseUrl2 + "/visibility/visibility-user-upload";
  getUploadVisiblityUsers = this.baseUrl2 + "/visibility/get-visibility-user-upload";
  CreateSchemenUploadVisiblityUsers = this.baseUrl2 + "/visibility/create-chiller-bonus-point-scheme";
  getUploadSchemeVisiblityUsers = this.baseUrl2 + "/visibility/get-chiller-bonus-assigned-users";
  UpdateUploadSchemeVisiblityUsers = this.baseUrl2 + "/visibility/update-chiller-bonus-assigned-users";
  getChillerSchemes = this.baseUrl2 + "/visibility/get-chiller-bonus-point-scheme";
  updateSchemenUploadVisiblityUsers = this.baseUrl2 + "/visibility/update-chiller-bonus-point-scheme";
  getAllUsers = this.baseUrl2 + "/users/get-supervisors";
  /**
   * Reports
   */
  getAccountLoginReport = this.baseUrl2 + "/adminreport/downloadhubadminreport";
  getPointsaccountstatement = this.baseUrl2 + "/adminreport/downloadhubadminreport";
  getEngagementstatusdetails = this.baseUrl2 + "/adminreport/engagementstatusdetails";

  getPointExipryRule = this.baseUrl2 + "/points-exipry-rule/get-point-exipry-rules";
  createPointExipryRule = this.baseUrl2 + "/points-exipry-rule/create-point-exipry-rule";
  updatePointExipryRule = this.baseUrl2 + "/points-exipry-rule/update-point-exipry-rule";

}
export interface ApiConfigurationInterface {

}
